/* eslint-disable camelcase */
import { DispatchPayloadType } from '../reducers'
import {
  SignUpInterface,
  SignInInterface,
  ForgotPasswordInterface,
  ConfirmForgotPasswordInterface,
  SubscriptionInterface,
} from './interfaces'
import { Auth } from 'aws-amplify'
import {
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  SUBSCRIPTION,
  PROFILE_GET,
} from '../constants'
import { HTTP } from '../../utils/http'

export const signUp =
  ({
    password,
    email,
    firstName,
    lastName,
    token,
    birthdate,
    zipCode,
    consent,
    policyCheck,
    phone,
  }: SignUpInterface) =>
  async (dispatch: (event: any) => void) => {
    try {
      dispatch({
        type: SIGN_UP.PENDING,
      })
      const attributes = {
        birthdate,
        'custom:agreeToRecieveEmails': String(consent),
        'custom:agreeWithPrivacy': String(policyCheck),
        'custom:firstName': String(firstName),
        'custom:lastName': String(lastName),
        'custom:phone_number': String(phone),
      } as Record<string, string | boolean>

      if (zipCode) {
        attributes['custom:zipCode'] = zipCode
      }

      await Auth.signUp({
        username: email,
        password,
        validationData: {
          recaptcha_token: token,
        },
        attributes,
      })
      dispatch({
        type: SIGN_UP.SUCCESS,
      })
    } catch (error: any) {
      dispatch({
        type: SIGN_UP.ERROR,
        payload: error.message,
      })
    }
  }

export const signUpReset = () => ({
  type: SIGN_UP.RESET,
})

export const isLoggedIn = () => async (dispatch: (event: DispatchPayloadType) => void) => {
  dispatch({
    type: SIGN_IN.SUCCESS,
  })
  localStorage.setItem('login', 'true')
}

export const signIn =
  ({ password, email }: SignInInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: SIGN_IN.PENDING,
      })
      await Auth.signIn(email, password)
      dispatch({
        type: SIGN_IN.SUCCESS,
      })
      localStorage.setItem('login', 'true')
    } catch (error: any) {
      dispatch({
        type: SIGN_IN.ERROR,
        payload: error.message,
      })
    }
  }

export const logout = async (withoutRedirect?: boolean) => {
  localStorage.clear()
  if (!withoutRedirect) {
    window.location.href = '/'
  }
  Auth.signOut({
    global: true,
  })
}

export const signInReset = () => ({
  type: SIGN_IN.RESET,
})

export const forgotPassword =
  ({ email }: ForgotPasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: FORGOT_PASSWORD.PENDING,
      })
      await Auth.forgotPassword(email)
      dispatch({
        type: FORGOT_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD.ERROR,
      })
    }
  }

export const forgotPasswordReset = () => ({
  type: FORGOT_PASSWORD.RESET,
})

export const confirmForgotPassword =
  ({ email, code, password }: ConfirmForgotPasswordInterface) =>
  async (dispatch: (event: DispatchPayloadType) => void) => {
    try {
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.PENDING,
      })
      await Auth.forgotPasswordSubmit(email, code, password)
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: CONFIRM_FORGOT_PASSWORD.ERROR,
      })
    }
  }

export const confirmForgotPasswordReset = () => ({
  type: CONFIRM_FORGOT_PASSWORD.RESET,
})

export const SubscriptionAction =
  (body: SubscriptionInterface) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: SUBSCRIPTION.PENDING })
      const response = await HTTP.post('api/subscribe', body)
      dispatch({ type: SUBSCRIPTION.SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({
        type: SUBSCRIPTION.ERROR,
        error: error,
      })
    }
  }

export const profile = () => async (dispatch: (event: DispatchPayloadType) => void) => {
  try {
    dispatch({ type: PROFILE_GET.PENDING })
    const response = await HTTP.get('api/profile')
    dispatch({ type: PROFILE_GET.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PROFILE_GET.ERROR, payload: error })
  }
}

export const SubscriptionResetAction = () => ({
  type: SUBSCRIPTION.RESET,
})
