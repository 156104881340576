import React, { useEffect, useState } from 'react'
import InnerPage from '../InnerPage'
import './styles.scss'
import Form from './components/Form'

const SignUp = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
    })
  }, [])
  return (
    <>
      <InnerPage>
        <div className='sign-up-container sign-up-parent-container'>
          <div className='container'>
            <div className='sign-up-content'>
              <div className='title-box'>
                <h1>Sign Up in Seconds</h1>
              </div>
              <Form />
            </div>
          </div>
          {windowWidth <= 992 ? (
            <img className='banner' src={require('../../assets/images/login/mobile-banner.png')} />
          ) : (
            <img className='banner' src={require('../../assets/images/login/banner-login.png')} />
          )}
        </div>
      </InnerPage>
    </>
  )
}

export default SignUp
