/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, FC } from 'react'
import { Typography, useMediaQuery, Box } from '@mui/material'
import axios from 'axios'
import styles from './styles'
import './styles.scss'

const AgeGate: FC<{ isValid?: (e: boolean) => void }> = (props) => {
  const matches900px = useMediaQuery('(max-width:900px)')
  // const isLandscape = useMediaQuery('(max-height:450px)')
  const [date, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [errorText, setErrorText] = useState('')

  const checkFormValidations = (event: any) => {
    event.preventDefault()
    if (Number(month) > 12 || Number(month) < 1) {
      setErrorText('Month must be between 1 and 12')
      setMonth('')
      document.getElementById('month')?.focus()
    } else if (Number(date) > 31 || Number(date) < 1) {
      setErrorText('Date must be between 1 and 31')
      setDate('')
      document.getElementById('day')?.focus()
    } else if (Number(year) > 2023 || Number(year) < 1900) {
      setErrorText('Year must be between 1900 and 2023')
      setYear('')
      document.getElementById('year')?.focus()
    } else {
      setErrorText('')
      fetchAgeGateResult()
    }
  }

  const setCookie = (cname: string, cvalue: string) => {
    // const d = new Date();
    // d.setTime(d.getTime() + exdays  24  60  60  1000);
    // let expires = "expires=" + d.toUTCString();
    const cookieString = `${cname}=${cvalue};path=/`
    document.cookie = cookieString
    window.scrollTo({
      top: 0,
      left: 0,
    })
    props.isValid && props.isValid(true)
  }

  const fetchAgeGateResult = () => {
    const formSelector = document.getElementById('form')
    // let btnSelector = document.getElementById("submit");

    if (formSelector) {
      const url = formSelector.getAttribute('action')
      const countryCodeElement = document.getElementById('countryCode') as any
      const countryCode = countryCodeElement?.value || ''
      // attempts
      const agAttempts = Number(localStorage.getItem('agAttempts')) || 0
      // window.addEventListener('beforeunload', (event) => {
      //   event.preventDefault()
      //   localStorage.setItem('agAttempts', '0')
      // })
      axios
        .get(
          `${url}?T=2CECF070-1B17-4514-BE3B-2E6EE9E2ADF9&D=${date}&M=${month}&Y=${year}&C=${countryCode}&R=JSON`,
        )
        .then((res) => {
          if (res.data.IsOfLegalDrinkingAge === true && res.data.IsCountryRestricted === false) {
            // set cookie
            setCookie('agegate', 'valid')
          } else {
            setErrorText('Your are not eligible to access this site')
            setDate('')
            setMonth('')
            setYear('')
            if (agAttempts < 2) {
              // add to agAttempts
              localStorage.setItem('agAttempts', `${agAttempts + 1}`)
            } else {
              window.location.href = 'https://www.tapintoyourbeer.com/'
            }
          }
        })
        .catch(() => {})
    }
  }

  return (
    <div className={'age_gate_container'}>
      <div className={'age_gate_content'}>
        <div className={'age_gate_input_container'}>
          <div className={'age_gate_form'}>
            <img
              src={require('../../assets/images/ageGate/BLCoreLogoHorizontal.svg').default}
              alt=''
              className={'age_gate_logo'}
            />

            <div className={'age_gate_title'}>
              <p className={'age_gate_header'}>WE NEED TO CHECK YOUR ID</p>
              <p className={'age_gate_desc'}>
                YOU NEED TO BE OF LEGAL DRINKING AGE TO ENTER THIS SITE
              </p>
            </div>

            <Box className={'responsive-flex-show-element'} sx={styles.errorText}>
              <Typography>{errorText}</Typography>
            </Box>

            <Box sx={styles.formContainer}>
              <form
                action='https://webshop.anheuser-busch.com/lda/AgeCheck.aspx'
                id='form'
                method='GET'
                className='form-inline first agegateform px-4 responsive-hide'
              >
                <input id='countryCode' type='hidden' value='US' name='countryCode' />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        width: matches900px ? '100px' : '150px',
                      }}
                      sx={styles.ageInput}
                    >
                      <input
                        type='number'
                        id='month'
                        placeholder='MM'
                        min={1}
                        max={12}
                        maxLength={2}
                        value={month}
                        onChange={(e) => {
                          if (/^\d+$/.test(e.target.value) || !e.target.value.length) {
                            setMonth(e.target.value)
                            month.length == 1 && document.getElementById('day')?.focus()
                          }
                        }}
                        required
                        autoFocus
                      />
                    </Box>
                    <Box
                      style={{
                        width: matches900px ? '100px' : '150px',
                      }}
                      sx={styles.ageInput}
                    >
                      <input
                        type='number'
                        id='day'
                        placeholder='DD'
                        min='1'
                        max='31'
                        maxLength={2}
                        value={date}
                        onChange={(e) => {
                          if (/^\d+$/.test(e.target.value) || !e.target.value.length) {
                            setDate(e.target.value)
                            date.length == 1 && document.getElementById('year')?.focus()
                          }
                        }}
                        required
                      />
                    </Box>
                    <Box
                      style={{
                        width: matches900px ? '100px' : '150px',
                      }}
                      sx={styles.ageInput}
                    >
                      <input
                        type='number'
                        id='year'
                        placeholder='YYYY'
                        min='1900'
                        max='2500'
                        maxLength={4}
                        value={year}
                        onChange={(e) => {
                          if (/^\d+$/.test(e.target.value) || !e.target.value.length) {
                            setYear(e.target.value)
                          }
                        }}
                        required
                      />
                    </Box>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <button className={'age_gate_button'} onClick={checkFormValidations}>
                      ENTER
                    </button>
                  </div>
                </div>
              </form>
            </Box>
            <div className={'age_gate_responsibility'}>
              <p className={'age_gate_responsibility_title'}>ENJOY RESPONSIBLY</p>
              <p className={'age_gate_responsibility_desc'}>
                Enjoy responsibly. © 2023 Anheuser-Busch, Bud Light® Beer, St. Louis, MO.
                <br /> Do Not Share This Content With Those Under 21.
              </p>
            </div>
          </div>
        </div>
        <div className={'age_gate_bg_container'}></div>
      </div>
    </div>
  )
}

export default AgeGate
