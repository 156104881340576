import React, { FC, useEffect, useState } from 'react'
import { InterfacesWeekDays } from '../interfaces'
import { Auth } from 'aws-amplify'

const WeekDay: FC<InterfacesWeekDays> = ({ profileData, getEmGamesData }) => {
  const [fullName, setFullName] = useState({
    firstName: '',
    lastName: '',
  })
  const [completedState, setCompletedState] = useState(false)

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const userAttributes = await Auth.userAttributes(user)
        const attributesMap: Record<string, string> = {}
        if (attributesMap) {
          userAttributes.forEach((attribute) => {
            attributesMap[attribute.Name] = attribute.Value
          })
          setFullName({
            firstName: attributesMap['custom:firstName'] || attributesMap['name'],
            lastName: attributesMap['custom:lastName'],
          })
        }
      } catch (error) {
        console.error('Error fetching user attributes:', error)
      }
    }

    fetchUserAttributes()
  }, [])

  useEffect(() => {
    let completed = true
    if (getEmGamesData?.data.games) {
      for (const data of getEmGamesData.data.games) {
        if (!data.completed && !data.isGameLocked && !data.pickState.winner.pickedTeamId) {
          completed = false
          break
        }
      }
      setCompletedState(completed)
    }
  }, [getEmGamesData])

  return (
    <div className='pick-em-game-day-box'>
      <p className='pick-em-game-day-box-header'>
        {completedState ? 'Your Picks' : 'Make Your Picks'}{' '}
      </p>
      <div className='pick-em-game-rank-box'>
        <p className='lock-box'>
          Lock in your prediction before each game tips off! Don’t miss your chance to score more
          entries to <span>Win a Weekend Trip to Vegas</span>
        </p>
        <p className='pick-em-game-day-box-header'>
          {fullName.firstName} {fullName?.lastName?.slice(0, 1) || ''}{' '}
        </p>
        {profileData && (
          <>
            <div className='pick-em-game-rank-item'>
              <img src={require('../../../assets/images/em-games/chart-icon.svg').default} />
              <p>
                YOUR <span>RANK: {profileData?.data.rank || ''}</span>
              </p>
            </div>
            <div className='pick-em-game-rank-item'>
              <img src={require('../../../assets/images/em-games/accuracy-icon.svg').default} />
              <p>
                YOUR <span>ACCURACY: {Math.round(profileData?.data.accuracy)}%</span>
              </p>
            </div>
            <div className='pick-em-game-rank-item'>
              <img src={require('../../../assets/images/em-games/rating-icon.svg').default} />
              <p>
                YOUR <span>TOTAL ENTRIES: {profileData?.data.entries || 0}</span>
              </p>
            </div>
          </>
        )}
      </div>
      <p className='pick-em-game-day'>*All game times are EST.</p>
    </div>
  )
}

export default WeekDay
