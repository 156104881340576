/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react'
import '../styles.scss'
import Input from '../../../core-ui/Input'
import Button from '../../../core-ui/Button'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SignInInterface, SignUpInterface } from '../../../store/actions/interfaces'
import { signUp, signUpReset, signInReset, signIn } from '../../../store/actions/AuthActions'
import { FormInterface } from '../interface'
import { emailRegex, passwordRegex, zipCodeRegex } from '../../../utils/regex'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { Checkbox } from 'antd'
import { useMediaQuery } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import PasswordPopover from './PasswordPopover'
import CheckEmailModal from './CheckEmailModal'
import { DateField } from '@mui/x-date-pickers'
// import SocialLogin from '../components/SocialLogin'
import ReCAPTCHA from 'react-google-recaptcha'

const Recaptcha = ReCAPTCHA as any

const Form: FC<FormInterface> = ({
  signUpAction,
  authReducer: { signUpStatus, signInStatus, errorMessage },
  signUpActionReset,
}) => {
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null)
  const matches900px = useMediaQuery('(max-width:900px)')
  const [zipCode, setZipCode] = useState('')
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [policyCheck, setPolicyCheck] = useState(false)
  const [pickerCalendar, setPickerCalendar] = useState(false)
  const [passwordState, setPasswordState] = useState(false)
  const [policyCheckState, setPolicyCheckState] = useState(false)
  const [checkEmailModal, setCheckEmailModal] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [consent, setConsent] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const [formData, setFormData] = useState([
    {
      name: 'First Name',
      icon: <img alt='' src={require('../../../assets/images/icons/user-icon.svg').default} />,
      key: 'firstName',
      value: '',
      errorText: '*Please enter your first name',
      errorVisible: false,
    },
    {
      name: 'Email',
      icon: <img alt='' src={require('../../../assets/images/icons/email-icon.svg').default} />,
      key: 'email',
      type: '',
      value: '',
      errorText: '*Please enter a valid email address',
      errorVisible: false,
    },

    {
      name: 'Phone',
      icon: <img alt='' src={require('../../../assets/images/icons/phone.png')} />,
      key: 'phone',
      type: 'number',
      value: '',
      errorText: '*Please enter a valid phone number',
      errorVisible: false,
    },
    {
      name: 'Date of Birth',
      icon: <img alt='' src={require('../../../assets/images/icons/calendar-icon.svg').default} />,
      key: 'birthdate',
      value: '',
      errorText: '*All players must be at least 21 years old.',
      errorVisible: false,
    },
    {
      name: 'Password',
      icon: <img alt='' src={require('../../../assets/images/icons/password-icon.svg').default} />,
      key: 'password',
      value: '',
      type: 'password',
      errorText: '*Please enter a valid password.',
      errorVisible: false,
    },
    {
      name: 'Confirm Password',
      icon: <img alt='' src={require('../../../assets/images/icons/password-icon.svg').default} />,
      key: 'confirmPassword',
      value: '',
      type: 'password',
      // eslint-disable-next-line quotes
      errorText: "*Passwords don't match",
      errorVisible: false,
    },
  ])

  useEffect(() => {
    return () => {
      signUpActionReset()
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldKey: string) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === fieldKey)
      let value =
        fieldKey === 'firstName' ? event.target.value : event.target.value.replace(/\s/g, '')
      if (fieldKey === 'email') {
        value = value?.toLowerCase()?.trim() ?? ''
      }
      result[key].value = value
      result[key].errorVisible = false
      return result
    })
  }

  const handleChangeBirth = (value: string) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === 'birthdate')
      result[key].value = value
      result[key].errorVisible = false
      return result
    })
  }

  useEffect(() => {
    if (signUpStatus === 'success' && signInStatus !== 'pending') {
      setRecaptchaToken('')
      recaptchaRef?.current?.reset()
      setCheckEmailModal(true)
    }
    if (signUpStatus === 'error') {
      recaptchaRef?.current?.reset()
      setRecaptchaToken('')
    }
  }, [signUpStatus, signInStatus])

  const formDataFunc = () => {
    const result = {} as Record<string, string>
    for (const { key, value } of formData) {
      result[key] = value
    }
    return result
  }

  const formValidate = () => {
    const newData = [...formData]
    let isValid = true

    for (const [idx, { key, value }] of formData.entries()) {
      if (key === 'firstName' && value.split(' ').length < 2 && !value.trim()) {
        newData[idx].errorVisible = true
        isValid = false
      }

      if (key === 'phone' && !value.trim()) {
        newData[idx].errorVisible = true
        isValid = false
      }

      if (key === 'email' && !emailRegex.test(value.trim())) {
        newData[idx].errorVisible = true
        isValid = false
      }

      if (key === 'password' && !passwordRegex.test(value.trim())) {
        newData[idx].errorVisible = true
        isValid = false
      }

      if (key === 'confirmPassword' && value !== newData[idx - 1].value) {
        newData[idx].errorVisible = true
        isValid = false
      }

      if (key === 'birthdate' && moment().diff(moment(value), 'years') < 21) {
        newData[idx].errorVisible = true
        isValid = false
      }
    }

    if (!zipCodeRegex.test(zipCode)) {
      setZipCodeError(true)
      isValid = false
    }

    if (lastName === '') {
      setLastNameError(true)
      isValid = false
    }

    if (!policyCheck) {
      setPolicyCheckState(true)
      isValid = false
    }

    setFormData(newData)

    return isValid
  }

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipCodeError(false)
    setZipCode(e.target.value.replace(/\s/g, ''))
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameError(false)
    setLastName(e.target.value.replace(/\s/g, ''))
  }

  const handleClick = async () => {
    if (formValidate() && recaptchaToken) {
      const { birthdate, password, firstName, email, phone } = formDataFunc()
      signUpAction({
        birthdate: moment(birthdate).format('MM/DD/YYYY'),
        zipCode: zipCode,
        password,
        phone,
        email,
        consent,
        policyCheck,
        firstName: firstName,
        lastName: lastName,
        token: recaptchaToken,
      })

      setUserEmail(email)
    }
  }
  const onRecaptchaChange = (value: string | null) => {
    setRecaptchaToken(value || '')
  }

  const handleFocusValidation = (val: string, key: string) => {
    if (key === 'password') {
      if (val === 'focus') {
        setPasswordState(true)
      } else {
        setPasswordState(false)
      }
    }
  }

  const handleModalClose = () => {
    setCheckEmailModal(false)
  }

  return (
    <>
      <div className='form-box'>
        <div className='form-box-content'>
          {formData.map(({ name, icon, value, errorText, errorVisible, key, type }) =>
            key === 'birthdate' ? (
              <div className='form-double-box' key={key}>
                <div className='form-box-item'>
                  <label>{name} *</label>
                  <div
                    className='calendar-box'
                    onClick={() => {
                      !matches900px && setPickerCalendar(true)
                    }}
                  >
                    {icon}
                    {matches900px ? (
                      <DateField
                        value={value || null}
                        onChange={(newValue) => handleChangeBirth(newValue || '')}
                      />
                    ) : (
                      <DatePicker
                        value={value || null}
                        open={pickerCalendar}
                        onClose={() => setPickerCalendar(false)}
                        closeOnSelect
                        onChange={(newValue) => handleChangeBirth(newValue || '')}
                      />
                    )}
                  </div>
                  <p className={`auth-error ${errorVisible || zipCodeError ? 'visible' : ''}`}>
                    {errorVisible
                      ? errorText
                      : zipCodeError
                        ? '*Please enter a valid zip code'
                        : errorText}
                  </p>
                </div>
                <div className='form-box-item'>
                  <label>Zip Code *</label>
                  <Input
                    type={'text'}
                    placeholder={'Zip code'}
                    onChange={handleZipCodeChange}
                    value={zipCode}
                    name={'zip code'}
                    icon={
                      <img
                        alt=''
                        src={require('../../../assets/images/icons/location.svg').default}
                      />
                    }
                  />
                </div>
              </div>
            ) : key === 'firstName' ? (
              <div className='form-double-box' key={key}>
                <div className='form-box-item' key={key}>
                  <label>{name} *</label>
                  <Input
                    type={type}
                    placeholder={name}
                    onChange={(e) => handleChange(e, key)}
                    value={value}
                    name={name}
                    icon={icon}
                    onFocus={() => handleFocusValidation('focus', key)}
                    onBlur={() => handleFocusValidation('blur', key)}
                  />
                  <p className={`auth-error ${errorVisible || lastNameError ? 'visible' : ''}`}>
                    {errorVisible
                      ? errorText
                      : lastNameError
                        ? '*Please enter a valid last name'
                        : errorText}
                  </p>{' '}
                </div>
                <div className='form-box-item'>
                  <label>Last Name *</label>
                  <Input
                    type={'text'}
                    placeholder={'Last Name'}
                    onChange={handleLastNameChange}
                    value={lastName}
                    name={'lastName'}
                    icon={icon}
                  />
                </div>
              </div>
            ) : (
              <div className='form-box-item' key={key}>
                <label>{name} *</label>
                <Input
                  type={type}
                  placeholder={name}
                  onChange={(e) => handleChange(e, key)}
                  value={value}
                  name={name}
                  icon={icon}
                  onFocus={() => handleFocusValidation('focus', key)}
                  onBlur={() => handleFocusValidation('blur', key)}
                />
                <p className={`auth-error ${errorVisible ? 'visible' : ''}`}>{errorText}</p>
                {key === 'password' && passwordState && <PasswordPopover password={value} />}
              </div>
            ),
          )}
        </div>
        <Checkbox
          className='form-policy-box'
          onChange={(e) => {
            setPolicyCheck(e.target.checked)
            setPolicyCheckState(false)
          }}
        >
          <p>
            Yes, I understand and agree to the{' '}
            <a
              href='https://www.budlight.com/terms-and-conditions'
              target='_blank'
              rel='noreferrer'
            >
              Terms & Conditions
            </a>
            ,{' '}
            <a
              href='https://www.anheuser-busch.com/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href='https://www.budlight.com/easypicksrules' target='_blank' rel='noreferrer'>
              Official Rules
            </a>{' '}
            which describe how the information I provide may be used.
            <span>*</span>
          </p>
        </Checkbox>
        <Checkbox
          className='form-policy-box'
          onChange={(e) => {
            setConsent(e.target.checked)
          }}
        >
          <p>
            Yes, I consent to affiliates using my personal information to provide me with product
            and marketing information.
          </p>
        </Checkbox>
        {policyCheckState && (
          <p className='auth-error mt-0'>Please consent to the Terms of Use and Privacy Policy</p>
        )}
        {signUpStatus === 'error' && (
          <p className='auth-error'>{errorMessage.replace('PreSignUp failed with error', '')}</p>
        )}
        <Recaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
          onChange={onRecaptchaChange}
          ref={recaptchaRef}
          hl='en'
        />
        {/* <SocialLogin /> */}
      </div>
      <div className='bottom-section'>
        <Button
          loading={signUpStatus === 'pending' || signInStatus === 'pending'}
          name='GET STARTED'
          onClick={handleClick}
        />
        <p>
          Already have an account ? <Link to='/sign-in'>Sign in</Link>
        </p>
      </div>
      <CheckEmailModal
        handleModalClose={handleModalClose}
        open={signUpStatus !== 'pending' && signUpStatus !== 'error' && checkEmailModal}
        userEmail={userEmail}
      />
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signUpAction: (body: SignUpInterface) => dispatch(signUp(body)),
  signInAction: (body: SignInInterface) => dispatch(signIn(body)),
  signUpActionReset: () => dispatch(signUpReset()),
  signInActionReset: () => dispatch(signInReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Form)
