import { HTTP } from '../../utils/http'
import { DispatchPayloadType } from '../reducers'
import { GET_EM_GAMES, SAVE_EM_GAMES, UPDATE_EM_GAMES } from '../constants'
import { SaveEmGamesBody, UpdateEmGamesBody } from './interfaces'

export const getEmGames = () => async (dispatch: (event: DispatchPayloadType) => void) => {
  try {
    dispatch({ type: GET_EM_GAMES.PENDING })
    const response = await HTTP.get('api/games')
    dispatch({ type: GET_EM_GAMES.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: GET_EM_GAMES.ERROR, payload: error })
  }
}

export const getEmGamesReset = () => ({
  type: GET_EM_GAMES.RESET,
})

export const saveEmGames = (body: SaveEmGamesBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SAVE_EM_GAMES.PENDING })
    const response = await HTTP.post('api/picks', body)
    dispatch({ type: SAVE_EM_GAMES.SUCCESS, payload: response.data })
    dispatch(getEmGames())
  } catch (error) {
    dispatch({ type: SAVE_EM_GAMES.ERROR, payload: error })
  }
}

export const updateEmGames =
  (body: UpdateEmGamesBody[]) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: UPDATE_EM_GAMES.PENDING })
      const response = await HTTP.patch('api/picks', { picks: body })
      dispatch({ type: UPDATE_EM_GAMES.SUCCESS, payload: response.data })
      dispatch(getEmGames())
    } catch (error) {
      dispatch({ type: UPDATE_EM_GAMES.ERROR, payload: error })
    }
  }
