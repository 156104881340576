import React, { FC, useState, useEffect } from 'react'
import '../../SignUp/styles.scss'
import Input from '../../../core-ui/Input'
import Button from '../../../core-ui/Button'
import { connect } from 'react-redux'
import { ForgotPasswordInterface } from '../../../store/actions/interfaces'
import { forgotPassword, forgotPasswordReset } from '../../../store/actions/AuthActions'
import { FormInterface } from '../interface'
import { emailRegex } from '../../../utils/regex'
import { CombineInterface } from '../../../store/reducers/interfaces'

const Form: FC<FormInterface> = ({
  forgotPasswordAction,
  forgotPasswordActionReset,
  authReducer: { forgotPasswordStatus },
}) => {
  const [sendVerify, setSendVerify] = useState(false)
  const [formData, setFormData] = useState([
    {
      name: 'Email',
      icon: <img alt='' src={require('../../../assets/images/icons/email-icon.svg').default} />,
      key: 'email',
      value: '',
    },
  ])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldKey: string) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === fieldKey)
      result[key].value =
        fieldKey === 'email'
          ? event.target.value.replace(/\s/g, '').toLowerCase()
          : event.target.value.replace(/\s/g, '')
      return result
    })
  }

  useEffect(() => {
    if (forgotPasswordStatus === 'success') {
      forgotPasswordActionReset()
      setSendVerify(true)
      // navigate(
      //   `/reset-password?email=${encodeURIComponent((formData[0] && formData[0]?.value) || '')}`,
      // )
    }
  }, [forgotPasswordStatus])

  const formDataFunc = () => {
    const result = {} as Record<string, string>
    for (const { key, value } of formData) {
      result[key] = value
    }
    return result
  }

  const handleClick = async () => {
    const { email } = formDataFunc()
    if (emailRegex.test(email)) {
      forgotPasswordAction({ email })
    }
  }

  const handleButtonDisabled = () => {
    const { email } = formDataFunc()

    if (emailRegex.test(email)) {
      return false
    }

    return true
  }

  return (
    <>
      <div className='form-box sign-in-form'>
        <div className='form-box-content'>
          {formData.map(({ name, icon, value, key }) => (
            <div className='form-box-item' key={key}>
              <label>{name}</label>
              <Input
                placeholder={name}
                onChange={(e) => handleChange(e, key)}
                value={value}
                icon={icon}
              />
            </div>
          ))}
        </div>
        {sendVerify && <p className='verify_success'>Please check your email</p>}
        <Button
          disabled={handleButtonDisabled()}
          loading={forgotPasswordStatus === 'pending'}
          name='Verify'
          onClick={handleClick}
        />
      </div>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  forgotPasswordAction: (body: ForgotPasswordInterface) => dispatch(forgotPassword(body)),
  forgotPasswordActionReset: () => dispatch(forgotPasswordReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Form)
