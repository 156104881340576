import React from 'react'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
// import Wrapped from './components/Wrapped/index'
import SignIn from './components/SignIn/index'
import SignUp from './components/SignUp'
import PickEmGame from './components/PickEmGame'
import PageNotFound from './components/404'
import EmailVerification from './components/EmailVerification'
import LeaderBoard from './components/LeaderBoard'
import Prizing from './components/Prizing'
// import SignedUp from './components/SignedUp'

export const router = [
  {
    path: '*',
    element: <PageNotFound />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    route: 'auth',
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    route: 'auth',
  },
  // {
  //   path: '/',
  //   element: <Wrapped />,
  //   route: 'auth',
  // },
  {
    path: '/sign-in',
    element: <SignIn />,
    route: 'auth',
  },
  {
    path: '/',
    element: <SignUp />,
    route: 'auth',
  },
  {
    path: '/pick-em-game',
    element: <PickEmGame />,
    route: 'private',
  },
  // {
  //   path: '/signed-up',
  //   element: <SignedUp />,
  //   route: 'private',
  // },
  {
    path: '/confirm-user',
    element: <EmailVerification />,
    route: 'auth',
  },
  {
    path: '/leaderboard',
    element: <LeaderBoard />,
    route: '',
  },
  {
    path: '/prizes',
    element: <Prizing />,
    route: '',
  },
]
