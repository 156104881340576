import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import '../styles.scss'
import { Auth } from 'aws-amplify'
import { CircularProgress } from '@mui/material'

interface CheckEmailModalProps {
  handleModalClose: () => void
  open: boolean
  userEmail: string
}

const CheckEmailModal = (props: CheckEmailModalProps) => {
  const { handleModalClose, open, userEmail } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleResendButton = async () => {
    setIsLoading(true)
    await Auth.resendSignUp(userEmail)
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <>
        <div className='check_email_modal'>
          <div className='closeIcon'>
            <img
              alt=''
              onClick={() => handleModalClose()}
              className='x_icon'
              src={require('../../../assets/images/icons/X.svg').default}
            />
          </div>
          <div>
            <h3>Almost there! Verify your email address via the link we sent to your inbox.</h3>
          </div>
          <div className='resendContainer'>
            <p className='text1'>Didn&apos;t receive email yet?</p>
            <p onClick={handleResendButton} className='text2'>
              RESEND EMAIL
            </p>
            {isLoading ? (
              <CircularProgress
                size={32}
                style={{
                  alignContent: 'center',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  display: 'block',
                  color: '#348CCB',
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default CheckEmailModal
