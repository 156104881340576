import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import InnerPage from '../InnerPage'
import './styles.scss'

const EmailVerification = () => {
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const navigate = useNavigate()

  const username: string = searchParams.get('username') || ''
  const code: string = searchParams.get('code') || ''

  useEffect(() => {
    Auth.confirmSignUp(username, code)
      .then(() => {
        navigate('/sign-in')
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <InnerPage>
      <div className='container email-verification-container'>
        <div className='emailVerificationBox'>
          <h1>Email Verification</h1>
          {isLoading ? <p>Almost there! Your email address is Verifying</p> : <></>}
        </div>
        {isLoading ? (
          <CircularProgress
            size={48}
            style={{
              alignContent: 'center',
              marginRight: 'auto',
              marginLeft: 'auto',
              display: 'block',
              color: '#348CCB',
            }}
          />
        ) : (
          <div className='buttonContainer'>
            <button className='errorButton'>Something went wrong</button>
          </div>
        )}
      </div>
    </InnerPage>
  )
}

export default EmailVerification
