import React, { FC } from 'react'
import Header from './components/Header'
import { InnerPageInterface } from './interfaces'
import './styles.scss'
import Footer from './components/Footer'
import MobileFooter from './components/MobileFooter'

const InnerPage: FC<InnerPageInterface> = ({ children }) => {
  return (
    <>
      <Header />
      <div className='website-container'>{children}</div>
      <Footer />
      <MobileFooter />
    </>
  )
}

export default InnerPage
