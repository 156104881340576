import React from 'react'
import InnerPage from '../InnerPage'
import '../SignUp/styles.scss'
import Form from './components/Form'

const ResetPassword = () => {
  return (
    <>
      <InnerPage>
        <div className='sign-up-container'>
          <div className='sign-up-content'>
            <div className='title-box title-box-forgot-password'>
              <h1>Reset Password</h1>
            </div>
            <Form />
          </div>
        </div>
      </InnerPage>
    </>
  )
}

export default ResetPassword
