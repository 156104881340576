import React, { FC, useState, useEffect } from 'react'
import '../../SignUp/styles.scss'
import Input from '../../../core-ui/Input'
import Button from '../../../core-ui/Button'
import { connect } from 'react-redux'
import { SignInInterface } from '../../../store/actions/interfaces'
import { signIn, signInReset } from '../../../store/actions/AuthActions'
import { FormInterface } from '../interface'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { Link, useNavigate } from 'react-router-dom'
import CheckEmailModal from '../../SignUp/components/CheckEmailModal'
// import SocialLogin from '../../SignUp/components/SocialLogin'

const Form: FC<FormInterface> = ({
  signInAction,
  signInResetAction,
  authReducer: { signInStatus, errorMessage },
}) => {
  const navigate = useNavigate()
  const [checkEmailModal, setCheckEmailModal] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [formData, setFormData] = useState([
    {
      name: 'Email',
      icon: <img alt='' src={require('../../../assets/images/icons/email-icon.svg').default} />,
      key: 'email',
      value: '',
    },
    {
      name: 'Password',
      icon: <img alt='' src={require('../../../assets/images/icons/password-icon.svg').default} />,
      key: 'password',
      value: '',
      type: 'password',
    },
  ])

  useEffect(() => {
    return () => {
      signInResetAction()
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldKey: string) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === fieldKey)
      let value = event.target.value
      if (fieldKey === 'email') {
        value = value?.toLowerCase()?.trim() ?? ''
      }
      result[key].value = value
      return result
    })
  }

  const formDataFunc = () => {
    const result = {} as Record<string, string>
    for (const { key, value } of formData) {
      result[key] = value
    }
    return result
  }

  const handleClick = async () => {
    const { password, email } = formDataFunc()
    if (password && email) {
      signInAction({ password, email })
      setUserEmail(email)
    }
  }

  useEffect(() => {
    if (errorMessage === 'User is not confirmed.') {
      setCheckEmailModal(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (signInStatus === 'success') {
      navigate('/pick-em-game')
    }
  }, [signInStatus])

  const handleButtonDisabled = () => {
    const { password, email } = formDataFunc()

    if (password && email) {
      return false
    }

    return true
  }

  const handleModalClose = () => {
    setCheckEmailModal(false)
  }

  return (
    <>
      <div className='form-box sign-in-form sign-in-form-only'>
        <div className='form-box-content'>
          {formData.map(({ name, icon, value, key, type }) => (
            <div className='form-box-item' key={key}>
              <label>{name}</label>
              <Input
                type={type}
                placeholder={name}
                onChange={(e) => handleChange(e, key)}
                value={value}
                icon={icon}
              />
            </div>
          ))}
          {signInStatus === 'error' && (
            <p className='auth-error'>
              {errorMessage === 'User is not confirmed.'
                ? 'Please verify your email address'
                : errorMessage.replace('PreSignUp failed with error', '')}
            </p>
          )}
        </div>
        <Button
          loading={signInStatus === 'pending'}
          name='Sign In'
          onClick={handleClick}
          disabled={handleButtonDisabled()}
        />
        {/* <SocialLogin /> */}
      </div>
      <p>
        Don&apos;t have an account? <Link to='/'>Sign up</Link>
      </p>
      <p>
        <Link to='/forgot-password'>FORGOT PASSWORD</Link>
      </p>
      <CheckEmailModal
        handleModalClose={handleModalClose}
        open={checkEmailModal}
        userEmail={userEmail}
      />
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signInAction: (body: SignInInterface) => dispatch(signIn(body)),
  signInResetAction: () => dispatch(signInReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Form)
