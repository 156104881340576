import { combineReducers } from 'redux'
import authReducer from './AuthReducers'
import emGamesReducer from './EmGamesReducers'
import rankReducer from './LeaderboardReducers'

export type DispatchPayloadType = {
  type: string
  payload?: any
  error?: any
}

const rootReducer = combineReducers({
  authReducer,
  emGamesReducer,
  rankReducer,
})

export default rootReducer
