// import React, { FC } from 'react'
// import { Modal } from 'antd'
// import { SavePickGamesModalProps } from '../interfaces'

// const SavePickGamesModal: FC<SavePickGamesModalProps> = ({ open, onSave, onClose }) => {
//   return (
//     <Modal
//       wrapClassName='save-pick-games-modal'
//       open={open}
//       centered
//       footer={null}
//       onCancel={onClose}
//       closeIcon={<img src={require('../../../assets/images/em-games/modal-close.svg').default} />}
//     >
//       <p className='save-pick-games-title'>Picks Locked.</p>
//       <p className='save-pick-games-desc'>
//         Your predictions are now locked in for this week. Good luck!
//       </p>
//       <p className='save-pick-games-desc'>Come back next week for more chances to win.</p>
//       <div className='buttons-box'>
//         <button onClick={onSave}>VIEW MY PICKS</button>
//       </div>
//     </Modal>
//   )
// }

// export default SavePickGamesModal

import React, { FC } from 'react'
import { Modal } from 'antd'
import { SavePickGamesModalProps } from '../interfaces'

const SavePickGamesModal: FC<SavePickGamesModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      wrapClassName='save-pick-games-modal'
      open={open}
      centered
      footer={null}
      onCancel={onClose}
      closeIcon={<img src={require('../../../assets/images/em-games/modal-close.svg').default} />}
    >
      <p className='save-pick-games-title'>
        Congrats! <br /> Your picks are set.
      </p>
      <img
        className='bud-light'
        src={require('../../../assets/images/em-games/logo-icon.svg').default}
      />
      {/* <div className='picks-box'>
        <div className='picks-item-section-box'>
          <div className='picks-item-section'>
            <p>Iowa</p>
          </div>
        </div>
        <div className='picks-item-section loose'>
          <p>67% Picked</p>
        </div>
      </div>
      <div className='picks-box'>
        <div className='picks-item-section-box'>
          <div className='picks-item-section'>
            <p>TCU</p>
          </div>
        </div>
        <div className='picks-item-section active'>
          <p>35% Picked</p>
        </div>
      </div>
      <div className='picks-box'>
        <div className='picks-item-section-box'>
          <div className='picks-item-section'>
            <p>Missouri</p>
          </div>
        </div>
        <div className='picks-item-section progress'>
          <p>51% Picked</p>
        </div>
      </div>
      <div className='picks-box'>
        <div className='picks-item-section-box'>
          <div className='picks-item-section'>
            <p>Missouri</p>
          </div>
        </div>
        <div className='picks-item-section active'>
          <p>15% Picked</p>
        </div>
      </div> */}
    </Modal>
  )
}

export default SavePickGamesModal
