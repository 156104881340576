/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './styles.module.css'
// import { refreshToken } from 'Utils/https'

const MobileFooter = () => {
  const location = useLocation()
  const [activeFooter, setActiveFooter] = useState('one')

  useEffect(() => {
    if (location.pathname === '/pickem-game') {
      setActiveFooter('pickem')
    } else if (location.pathname === '/leaderboard') {
      setActiveFooter('leaderboard')
    } else if (location.pathname === '/profile') {
      setActiveFooter('profile')
    } else if (location.pathname === '/prizes') {
      setActiveFooter('prizes')
    } else {
      setActiveFooter('')
    }
  }, [location])

  return (
    <div className={styles.modal_footer}>
      <a href='/' className={activeFooter === 'pickem' ? styles.active : ''}>
        <img src={require('../../../../assets/images/mobile-menu/basketball.svg').default} />
        <p>Play</p>
      </a>
      <a href='/leaderboard' className={`${activeFooter === 'leaderboard' ? styles.active : ''}`}>
        {/* <div className={styles.hover_button_box}>
                    <img src={tooltip} />
                    <button>Coming Soon</button>
                </div> */}
        <img src={require('../../../../assets/images/mobile-menu/leaderboard-icon.svg').default} />

        <p>Leaderboard</p>
      </a>
      <a href='/prizes' className={activeFooter === 'prizes' ? styles.active : ''}>
        <img src={require('../../../../assets/images/mobile-menu/prizing.svg').default} />

        <p>PRIZING</p>
      </a>
    </div>
  )
}

export default MobileFooter
