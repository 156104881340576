import { FC, useState } from 'react'
import { PrizingComponentInterface } from '../interfaces'

const PrizingComponent: FC<PrizingComponentInterface> = () => {
  // const [windowWidth, setWindowWidth] = useState(0)
  const [faqTable, setFaqTable] = useState([
    {
      title: 'When will winners be drawn ?',
      open: false,
      id: 0,
      description:
        'Winners will be drawn on March 31stand have the week to plan their travel to Las Vegas for the weekend ahead.',
    },
    {
      title: 'How do you enter the sweepstakes ?',
      open: false,
      id: 1,
      description:
        'You are automatically entered into the sweepstake after you enter your information in sign up. You can receive additional entries for every correct pick per game during the tournament. ',
    },
    {
      title: 'How do I win additional entries to the sweepstakes?',
      open: false,
      id: 2,
      description:
        'Each correct pick per game counts as one entry. Every entry you receive will automatically give you an additional chance to win the sweepstakes.',
    },
    {
      title: 'How do you book travel after winning?',
      open: false,
      id: 3,
      description:
        'A representative from the BudLight team will coordinate your round-trip flight for two to Las Vegas.',
    },
    {
      title: 'How will Bud light contact the winner?',
      open: false,
      id: 4,
      description:
        'Bud Light will contact the winner by email on April 1st and once you sign the affidavit and official accept the prize, our Bud Light representative will help coordinate prize fulfillment.',
    },
  ])
  const handleClickArrow = (index: number, type: string) => {
    const updatedFaqTable = [...faqTable]
    if (type === 'open') {
      updatedFaqTable[index] = { ...updatedFaqTable[index], open: true }
    } else {
      updatedFaqTable[index] = { ...updatedFaqTable[index], open: false }
    }

    setFaqTable(updatedFaqTable)
  }
  // useEffect(() => {
  //   setWindowWidth(window.innerWidth)
  //   window.addEventListener('resize', () => {
  //     setWindowWidth(window.innerWidth)
  //   })
  // }, [])

  return (
    <>
      <div className='prizing-section'>
        <div className='prizing-desk-part'>
          <div className='container'>
            <div className='prizing_inner_box'>
              <h1>Prizing</h1>
              <img
                className='prizing-mobile-banner'
                src={require('../../../assets/images/prizing/prizes-banner.png')}
              />
              <div className='weekend-box'>
                <h6>
                  What’s Included In Your <br /> Weekend Trip To Vegas
                </h6>
                <div className='border-box' />
                <div className='weekend-child-box'>
                  <img
                    className='air-icon'
                    src={require('../../../assets/images/prizing/air-icon-desk.png')}
                  />
                  <ul>
                    <li>(2) Roundtrip Airfare to Las Vegas</li>
                    <li>Hotel Stay (2 Nights - Double Occupancy)</li>
                    <li>Tickets to VIP Watch Party for Saturday, April 6th</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <img
            className='prizing-desk-banner'
            src={require('../../../assets/images/prizing/prizes-banner-desk.png')}
          />
        </div>
        <div className='container'>
          <div className='faq-section'>
            <h6>Faq</h6>
            <div className='faq-table'>
              {faqTable.map(({ title, open, description }, index) => (
                <div className={`form-box-item ${open ? 'form-box-item-active' : ''}`} key={title}>
                  <div
                    className='top-part'
                    onClick={() => handleClickArrow(index, open ? 'close' : 'open')}
                  >
                    <h6>{title}</h6>
                    <img
                      className={open ? 'open' : 'close'}
                      onClick={() => handleClickArrow(index, open ? 'close' : 'open')}
                      src={require('../../../assets/images/prizing/polygon.svg').default}
                    />
                  </div>
                  <p className={`${open ? 'active' : 'default'}`}>{description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrizingComponent
