import React, { FC, useState } from 'react'
import { Input as AntdInput } from 'antd'
import './styles.scss'
import { InputInterface } from './interfaces'

const Input: FC<InputInterface> = ({
  name,
  onFocus,
  onBlur,
  icon,
  onChange,
  inputRef,
  value,
  id,
  placeholder,
  type,
}) => {
  const [showPass, setShowPass] = useState(false)
  const handleClick = () => {
    setShowPass(!showPass)
  }
  return (
    <>
      <AntdInput
        onChange={onChange}
        value={value}
        ref={inputRef}
        id={id}
        className='input'
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        size='large'
        placeholder={placeholder}
        prefix={icon}
        suffix={
          (type === 'password' ? (
            <>
              {!showPass ? (
                <img
                  onClick={handleClick}
                  src={require('../assets/images/icons/eye-open.svg').default}
                />
              ) : (
                <svg
                  onClick={handleClick}
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M12.1583 5.27783C16.2485 5.45293 19.515 7.29451 21.6989 10.9782C22.1009 11.6538 22.0998 12.3593 21.6989 13.0394C19.8866 16.1359 17.2197 18.0214 13.6739 18.585C9.09498 19.3124 4.57519 17.088 2.29446 13.0433C1.90036 12.3486 1.90036 11.6505 2.30347 10.9602C4.08425 7.92395 6.71404 6.09306 10.1726 5.44673C10.4001 5.40424 10.6298 5.37379 10.8606 5.35553C11.2383 5.32569 11.6172 5.30936 12.1583 5.27783ZM11.9663 6.54515C11.5007 6.59132 10.9794 6.61215 10.4693 6.69942C7.3598 7.23202 5.01377 8.87317 3.41146 11.5835C3.24256 11.8712 3.24875 12.1465 3.41146 12.4392C5.37184 15.9445 9.43278 17.9623 13.3845 17.3492C16.5373 16.8599 18.9278 15.2148 20.5639 12.4725C20.7537 12.1544 20.7531 11.8616 20.5639 11.5452C18.6204 8.32875 15.748 6.71462 11.9663 6.54515Z'
                    fill='#348CCB'
                  />
                  <path
                    d='M12.0075 8.1265C12.7761 8.12817 13.527 8.35783 14.165 8.78641C14.8031 9.21498 15.2997 9.82321 15.5919 10.5341C15.8842 11.245 15.959 12.0266 15.8069 12.7801C15.6547 13.5335 15.2825 14.2249 14.7373 14.7667C14.1921 15.3085 13.4984 15.6764 12.7441 15.8238C11.9897 15.9712 11.2086 15.8916 10.4995 15.5949C9.79043 15.2982 9.18531 14.7978 8.76073 14.1571C8.33614 13.5164 8.11117 12.7641 8.11429 11.9955C8.1154 11.4857 8.21703 10.9811 8.41337 10.5106C8.60971 10.0401 8.8969 9.61295 9.25851 9.25359C9.62012 8.89423 10.0491 8.60971 10.5207 8.41631C10.9924 8.2229 11.4977 8.12442 12.0075 8.1265ZM11.9979 9.39776C11.4811 9.39809 10.9761 9.55165 10.5466 9.83901C10.1171 10.1264 9.78245 10.5346 9.58498 11.0122C9.38751 11.4897 9.33607 12.0151 9.43717 12.5219C9.53827 13.0286 9.78737 13.494 10.153 13.8592C10.5186 14.2245 10.9843 14.4731 11.4911 14.5736C11.998 14.6742 12.5233 14.6222 13.0007 14.4242C13.478 14.2262 13.8859 13.8911 14.1728 13.4613C14.4597 13.0315 14.6127 12.5263 14.6125 12.0095C14.6105 11.317 14.3344 10.6533 13.8444 10.1639C13.3544 9.67441 12.6905 9.39895 11.9979 9.39776Z'
                    fill='#348CCB'
                  />
                </svg>
              )}
            </>
          ) : (
            ''
          )) as any
        }
        type={type === 'password' && showPass ? 'text' : type}
      />
    </>
  )
}

export default Input
