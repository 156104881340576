/* eslint-disable camelcase */
import { FC, useEffect, useState } from 'react'
import InnerPage from '../InnerPage'
import './styles.scss'
import { connect } from 'react-redux'
import {
  getEmGames,
  getEmGamesReset,
  saveEmGames,
  updateEmGames,
} from '../../store/actions/EmGames'
import WeekDay from './components/WeekDay'
// import MobileGames from './components/MobileGames'
import Spinner from '../../core-ui/Spinner'
import { Hub } from 'aws-amplify'
import Games from './components/Games'
import { PickEmGameInterface } from './interfaces'
import { CombineInterface, emGamesType } from '../../store/reducers/interfaces'
import { SaveEmGamesBody, UpdateEmGamesBody } from '../../store/actions/interfaces'
import SavePickGamesModal from './components/SavePickGamesModal'
import { isLoggedIn, profile } from '../../store/actions/AuthActions'

const PickEmGame: FC<PickEmGameInterface> = ({
  getEmGamesAction,
  saveEmGamesAction,
  isLoggedInAction,
  updateEmGamesAction,
  emGamesReducer: { getEmGamesData, getEmGamesStatus, saveEmGamesStatus, updateEmGamesStatus },
  authReducer: { profileStatus, profileData },
  profileAction,
  getEmGamesResetAction,
}) => {
  const [weekGames, setWeekGames] = useState<emGamesType[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [activeModal, setActiveModal] = useState(false)
  const [editPicks, setEditPicks] = useState(false)

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data?.payload?.event === 'signIn') {
        isLoggedInAction()
      }
    })
  }, [])

  useEffect(() => {
    getEmGamesAction()
    profileAction()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const footer = document.getElementsByTagName('footer')
      const pickWeek = document.getElementsByClassName('pick-week')
      if (footer[0] && pickWeek[0]) {
        const footerY = footer[0].getBoundingClientRect()
        if (document.body.clientHeight - footerY.height <= scrollY + window.innerHeight) {
          pickWeek[0].setAttribute('style', `position: absolute; bottom: ${footerY.height}px`)
        } else {
          pickWeek[0].setAttribute('style', 'position: fixed; bottom: 0')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (saveEmGamesStatus === 'success' || updateEmGamesStatus === 'success') {
      getEmGamesAction()
      getEmGamesResetAction()
    }
  }, [saveEmGamesStatus, updateEmGamesStatus])

  useEffect(() => {
    const week = []
    let completed = true
    if (getEmGamesData?.data.group_by_date) {
      const games = []
      for (const groupGames of Object.values(getEmGamesData?.data.group_by_date)) {
        games.push(...groupGames)
      }
      for (const data of games) {
        const body = { ...data }
        if (body?.pickState?.winner?.pickedTeamId === body.awayTeam.id) {
          body.awayTeam.selected = true
        }
        if (body?.pickState?.winner?.pickedTeamId === body.homeTeam.id) {
          body.homeTeam.selected = true
        }
        if (!data.completed && !data.isGameLocked && !data.pickState.winner.pickedTeamId) {
          completed = false
        }
        week.push(body)
      }
      setWeekGames(week)
      setEditPicks(completed)
    }
  }, [getEmGamesData])

  const selectClick = (competitionId: string, type: string) => {
    setActiveModal(true)
    const data = [...weekGames]
    for (const [idx, { eventId }] of weekGames.entries()) {
      if (eventId === competitionId) {
        if (type === 'homeTeam') {
          data[idx].awayTeam.selected = false
          data[idx].homeTeam.selected = true
        } else {
          data[idx].awayTeam.selected = true
          data[idx].homeTeam.selected = false
        }
      }
    }

    setWeekGames(data)
  }

  const handleSave = () => {
    const saveBody = []
    const updateBody = []

    setModalOpen(true)

    for (const { awayTeam, homeTeam, eventId, pickState } of weekGames) {
      for (const { selected, id } of [awayTeam, homeTeam]) {
        if (selected) {
          if (pickState.winner.pickedTeamId) {
            if (pickState.winner.pickedTeamId !== id) {
              updateBody.push({
                eventId: eventId,
                predictedWinnerId: id,
              })
            }
          } else {
            saveBody.push({
              eventId,
              predictedWinnerId: id,
            })
          }
        }
      }
    }

    if (saveBody.length) {
      saveEmGamesAction({ picks: saveBody })
    }

    if (updateBody.length) {
      updateEmGamesAction(updateBody)
    }

    setActiveModal(false)
    setEditPicks(true)
  }

  const handleDisableButton = () => {
    // const isAllTeamSelected = weekGames.every((card) => {
    //   return (
    //     card.awayTeam?.selected ||
    //     (card.homeTeam?.selected && !card.completed && !card.isGameLocked)
    //   )
    // })
    if (getEmGamesData?.data.group_by_date) {
      const games = []
      for (const groupGames of Object.values(getEmGamesData?.data.group_by_date)) {
        games.push(...groupGames)
      }
      let isAllTeamSelected = false
      for (const card of weekGames) {
        if (!card.completed && !card.isGameLocked) {
          for (const { selected, id } of [card.awayTeam, card.homeTeam]) {
            if (selected) {
              if (card.pickState.winner.pickedTeamId) {
                if (card.pickState.winner.pickedTeamId !== id) {
                  isAllTeamSelected = true
                }
              } else {
                isAllTeamSelected = true
              }
            }
          }
        }
      }
      return isAllTeamSelected
    }
    return false
  }

  return (
    <>
      <InnerPage>
        <div className='pick-em-game-container'>
          <div className='container'>
            <WeekDay profileData={profileData} getEmGamesData={getEmGamesData} />
            {/* <MobileGames /> */}
            {getEmGamesStatus === 'success' && profileStatus === 'success' ? (
              <Games selectClick={selectClick} weekGames={weekGames} />
            ) : (
              <div className='spinner-box'>
                <Spinner />
              </div>
            )}
            {/* : (
               !weekGames.length &&
               getEmGamesStatus !== 'pending' && (
                 <h2 className='no-game'>All games are over, come back next year</h2>
               )
             )} */}
          </div>
        </div>
      </InnerPage>
      <div className='pick-week'>
        {/* <h6>Your Picks for Week {getEmGamesData?.data?.weeklyGames?.weekNumber}:</h6> */}
        <button
          className={handleDisableButton() && activeModal ? 'active-pick' : ''}
          onClick={() => activeModal && handleSave()}
          disabled={!handleDisableButton()}
        >
          {editPicks ? 'EDIT' : 'SAVE'} PICKS
        </button>
      </div>
      <SavePickGamesModal
        onSave={() => setModalOpen(false)}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

const mapStateFromProps = ({ emGamesReducer, authReducer }: CombineInterface) => ({
  emGamesReducer,
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  getEmGamesAction: () => dispatch(getEmGames()),
  isLoggedInAction: () => dispatch(isLoggedIn()),
  saveEmGamesAction: (body: SaveEmGamesBody) => dispatch(saveEmGames(body)),
  updateEmGamesAction: (body: UpdateEmGamesBody[]) => dispatch(updateEmGames(body)),
  profileAction: () => dispatch(profile()),
  getEmGamesResetAction: () => dispatch(getEmGamesReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(PickEmGame)
