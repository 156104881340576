export default {
  Auth: {
    identityPoolId: process.env.REACT_APP_GOOGLE_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    federationTarget: 'COGNITO_USER_POOLS',
  },
}
