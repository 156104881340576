import {
  SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  SIGN_IN,
  SUBSCRIPTION,
  PROFILE_GET,
} from '../constants'
import { DispatchPayloadType } from './index'

import { AuthReducersInterface } from './interfaces'

const initialState: AuthReducersInterface = {
  signUpStatus: '',

  signInStatus: '',

  forgotPasswordStatus: '',

  confirmForgotPasswordStatus: '',
  errorMessage: '',

  subsciptionStatus: '',
  subscriptionData: null,

  profileStatus: '',
  profileData: null,
}

const authReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): AuthReducersInterface => {
  switch (type) {
    case SIGN_UP.PENDING: {
      return {
        ...state,
        signUpStatus: 'pending',
      } as AuthReducersInterface
    }
    case SIGN_UP.SUCCESS: {
      return {
        ...state,
        signUpStatus: 'success',
      } as AuthReducersInterface
    }
    case SIGN_UP.ERROR: {
      return {
        ...state,
        signUpStatus: 'error',
        errorMessage: payload,
      } as AuthReducersInterface
    }
    case SIGN_UP.RESET: {
      return {
        ...state,
        signUpStatus: '',
      } as AuthReducersInterface
    }

    case SIGN_IN.PENDING: {
      return {
        ...state,
        signInStatus: 'pending',
      } as AuthReducersInterface
    }
    case SIGN_IN.SUCCESS: {
      return {
        ...state,
        signInStatus: 'success',
      } as AuthReducersInterface
    }
    case SIGN_IN.ERROR: {
      return {
        ...state,
        signInStatus: 'error',
        errorMessage: payload,
      } as AuthReducersInterface
    }
    case SIGN_IN.RESET: {
      return {
        ...state,
        signInStatus: '',
      } as AuthReducersInterface
    }

    case FORGOT_PASSWORD.PENDING: {
      return {
        ...state,
        forgotPasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        forgotPasswordStatus: 'success',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        forgotPasswordStatus: 'error',
      } as AuthReducersInterface
    }
    case FORGOT_PASSWORD.RESET: {
      return {
        ...state,
        forgotPasswordStatus: '',
      } as AuthReducersInterface
    }

    case CONFIRM_FORGOT_PASSWORD.PENDING: {
      return {
        ...state,
        confirmForgotPasswordStatus: 'pending',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        confirmForgotPasswordStatus: 'success',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        confirmForgotPasswordStatus: 'error',
      } as AuthReducersInterface
    }
    case CONFIRM_FORGOT_PASSWORD.RESET: {
      return {
        ...state,
        confirmForgotPasswordStatus: '',
      } as AuthReducersInterface
    }

    case SUBSCRIPTION.PENDING: {
      return {
        ...state,
        subsciptionStatus: 'pending',
      } as AuthReducersInterface
    }
    case SUBSCRIPTION.SUCCESS: {
      return {
        ...state,
        subsciptionStatus: 'success',
      } as AuthReducersInterface
    }
    case SUBSCRIPTION.ERROR: {
      return {
        ...state,
        subsciptionStatus: 'error',
        subscriptionData: error,
      } as AuthReducersInterface
    }
    case SUBSCRIPTION.RESET: {
      return {
        ...state,
        subsciptionStatus: '',
        subscriptionData: null,
      } as AuthReducersInterface
    }

    case PROFILE_GET.PENDING: {
      return {
        ...state,
        profileStatus: 'pending',
      } as AuthReducersInterface
    }
    case PROFILE_GET.SUCCESS: {
      return {
        ...state,
        profileStatus: 'success',
        profileData: payload,
      } as AuthReducersInterface
    }
    case PROFILE_GET.ERROR: {
      return {
        ...state,
        profileStatus: 'error',
        profileData: error,
      } as AuthReducersInterface
    }
    case PROFILE_GET.RESET: {
      return {
        ...state,
        profileStatus: '',
        profileData: null,
      } as AuthReducersInterface
    }

    default:
      return state
  }
}

export default authReducer
