import React, { useEffect, FC } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import '../styles.scss'
// import { logout } from '../../../store/actions/AuthActions'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { HeaderPropsInterface } from '../interfaces'

const Header: FC<HeaderPropsInterface> = ({ authReducer }) => {
  // const [loginState, setLoginState] = useState(true)

  // const handleNavigateToLogin = () => {
  //   if (loginState) {
  //     logout()
  //   }
  // }

  useEffect(() => {
    const login = localStorage.getItem('login')
    if (login) {
      // setLoginState(true)
    } else {
      // setLoginState(false)
    }
  }, [authReducer])

  return (
    <div className='header'>
      <div className='container'>
        <div className='header-left-box'>
          <div className='logo-box'>
            <Link to='/'>
              <img alt='' src={require('../../../assets/images/icons/header-logo.svg').default} />
            </Link>
          </div>
          <div className='menu-box'>
            <Link to='/'>PLAY NOW</Link>
            <Link to='/prizes'>PRIZES</Link>
            <Link to='/leaderboard'>LEADERBOARD</Link>
          </div>
        </div>
        <div className='header-sign-in-container'>
          {/* <div onClick={handleNavigateToLogin} className='header-sign-in'>
            <p>{loginState ? 'Sign Out' : 'Sign In'}</p>
          </div> */}
          {/* <div className='header-discord'>
            <img alt='' src={require('../../../assets/images/icons/discord.svg').default} />
          </div> */}
        </div>
        {/* <div className='burger-box'>
          <div />
          <div />
        </div> */}
      </div>
    </div>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateFromProps, mapDispatchToProps)(Header)
