import React, { FC, useState, useEffect } from 'react'
import '../../SignUp/styles.scss'
import Input from '../../../core-ui/Input'
import Button from '../../../core-ui/Button'
import { connect } from 'react-redux'
// import { useRouter } from 'next/router';
import { ConfirmForgotPasswordInterface, SignInInterface } from '../../../store/actions/interfaces'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  confirmForgotPassword,
  confirmForgotPasswordReset,
  signIn,
  signInReset,
} from '../../../store/actions/AuthActions'
// import { FormInterface } from '../interface';
// import { emailRegex, passwordRegex } from '../../../utils/regex';
import { CombineInterface } from '../../../store/reducers/interfaces'
import { FormInterface } from '../interfaces'

const Form: FC<FormInterface> = ({
  confirmForgotPasswordAction,
  authReducer,
  signInAction,
  signInResetAction,
  confirmForgotPasswordResetAction,
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [formData, setFormData] = useState([
    {
      name: 'New Password',
      icon: <img alt='' src={require('../../../assets/images/icons/password-icon.svg').default} />,
      key: 'password',
      value: '',
      type: 'password',
    },
    {
      name: 'Confirm Password',
      icon: <img alt='' src={require('../../../assets/images/icons/password-icon.svg').default} />,
      key: 'confirmPassword',
      value: '',
      type: 'password',
    },
  ])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldKey: string) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === fieldKey)
      result[key].value = event.target.value
      return result
    })
  }

  const formDataFunc = () => {
    const result = {} as Record<string, string>
    for (const { key, value } of formData) {
      result[key] = value
    }
    return result
  }

  const handleClick = () => {
    const { password, confirmPassword } = formDataFunc()
    // const email = decodeURIComponent(parsed?.email || '')
    if (password && password === confirmPassword) {
      confirmForgotPasswordAction({
        password,
        code: searchParams.get('code') || '',
        email: searchParams.get('email') || '',
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (authReducer.confirmForgotPasswordStatus === 'success') {
        confirmForgotPasswordResetAction()
        const { password } = formDataFunc()
        signInAction({ password, email: searchParams.get('email') || '' })
      }
    })()
  }, [authReducer])

  useEffect(() => {
    if (authReducer.signInStatus === 'success') {
      signInResetAction()
      navigate('/pick-em-game')
    }
  }, [authReducer])

  return (
    <>
      <div className='form-box sign-in-form'>
        <div className='form-box-content'>
          {formData.map(({ name, icon, value, key, type }) => (
            <div className='form-box-item' key={key}>
              <label>{name}</label>
              <Input
                placeholder={name}
                onChange={(e) => handleChange(e, key)}
                value={value}
                icon={icon}
                type={type}
              />
            </div>
          ))}
        </div>
        <Button
          loading={
            authReducer.signInStatus === 'pending' ||
            authReducer.confirmForgotPasswordStatus === 'pending'
          }
          name='Submit'
          onClick={handleClick}
        />
      </div>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  confirmForgotPasswordAction: (body: ConfirmForgotPasswordInterface) =>
    dispatch(confirmForgotPassword(body)),
  confirmForgotPasswordResetAction: () => dispatch(confirmForgotPasswordReset()),
  signInAction: (body: SignInInterface) => dispatch(signIn(body)),
  signInResetAction: () => dispatch(signInReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Form)
