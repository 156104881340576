import { RANK_GET } from '../constants'
import { DispatchPayloadType } from './index'

import { RankReducersInterface } from './interfaces'

const initialState: RankReducersInterface = {
  getRankStatus: '',
  getRankData: null,
}

const rankReducer = (
  state = initialState,
  { type, payload }: DispatchPayloadType,
): RankReducersInterface => {
  switch (type) {
    case RANK_GET.PENDING: {
      return {
        ...state,
        getRankStatus: 'pending',
      } as RankReducersInterface
    }
    case RANK_GET.SUCCESS: {
      return {
        ...state,
        getRankStatus: 'success',
        getRankData: payload,
      } as RankReducersInterface
    }
    case RANK_GET.ERROR: {
      return {
        ...state,
        getRankStatus: 'error',
        getRankData: null,
      } as RankReducersInterface
    }
    case RANK_GET.RESET: {
      return {
        ...state,
        getRankStatus: '',
        getRankData: null,
      } as RankReducersInterface
    }
    default:
      return state
  }
}

export default rankReducer
