/* eslint-disable camelcase */
import { FC } from 'react'
import InnerPage from '../InnerPage'
import './styles.scss'
import { PrizingInterface } from './interfaces'
import PrizingComponent from './components/Prizing'

const Prizing: FC<PrizingInterface> = () => {
  return (
    <>
      <InnerPage>
        <div className='prizing-container'>
          <PrizingComponent />
        </div>
      </InnerPage>
    </>
  )
}

export default Prizing
