import { HTTP } from '../../utils/http'
import { DispatchPayloadType } from '../reducers'
import { RANK_GET } from '../constants'

export const rankGet = () => async (dispatch: (event: DispatchPayloadType) => void) => {
  try {
    dispatch({ type: RANK_GET.PENDING })
    const response = await HTTP.get('api/ranks')
    dispatch({ type: RANK_GET.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: RANK_GET.ERROR, payload: error })
  }
}