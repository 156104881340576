import React, { FC } from 'react'
import './styles.scss'
import { ButtonInterface } from './interfaces'
import { CircularProgress } from '@mui/material'

const Button: FC<ButtonInterface> = ({ onClick, name, loading, disabled }) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`button ${disabled ? 'button-disabled' : ''}`}
      >
        {loading ? (
          <>
            <CircularProgress
              size={24}
              style={{
                alignContent: 'center',
                marginRight: 'auto',
                marginLeft: 'auto',
                display: 'block',
                color: '#fff',
              }}
            />
          </>
        ) : (
          <>{name}</>
        )}
      </button>
    </>
  )
}

export default Button
