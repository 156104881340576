export default {
  container: {
    width: '100%',
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  bgImageContainer: {
    backgroundImage: `url(${require('../../assets/images/ageGate/AgeGateBG.svg').default})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
  },
  logoContainer: {
    top: '10%',
    position: 'absolute',

    // mobile portrait devices
    '@media (max-width: 500px)': {
      top: '10%',
      '& img': {
        width: '200px !important',
      },
    },

    // mobile landscape devices
    '@media (max-height: 450px)': {
      top: '0 !important',
      '& img': {
        width: '100px !important',
        height: '100px !important',
      },
    },

    // tab portrait devices
    '@media (max-width: 910px)': {
      top: '15%',
    },

    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '10%',
    },
  },

  headerContainer: {
    top: '40%',
    position: 'absolute',

    // mobile portrait devices
    '@media (max-width: 500px)': {
      top: '30% !important',
    },

    // mobile landscape devices
    '@media (max-height: 450px)': {
      top: '28% !important',
    },

    // tab portrait devices
    '@media (max-width: 910px)': {
      top: '35%',
    },

    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '37%',
    },
  },
  textContainer: {
    fontFamily: 'TitlingGothicFBCompressed-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: { sm: '3rem', md: '4rem' },
    lineHeight: '100%',
    marginTop: { xs: '1rem', md: 0 },

    // mobile portrait devices
    '@media (max-width: 500px)': {
      fontSize: '3rem',
    },

    // mobile landscape devices
    '@media (max-height: 450px)': {
      fontSize: '2rem',
    },
  },
  description: {
    top: '55%',
    position: 'absolute',
    '& > p': {
      fontFamily: 'NeueHaasDisplay-Pro',
      fontSize: '1.125rem',
      lineHeight: '155%',
      fontStyle: 'normal',
      fontWeight: '500',
      textAlign: 'center',
      color: '#FFFFFF',
    },

    // mobile portrait devices
    '@media (max-width: 500px)': {
      top: '47% !important',
    },

    // mobile landscape devices
    '@media (max-height: 450px)': {
      top: '44% !important',
    },

    // tab portrait devices
    '@media (max-width: 910px)': {
      top: '50%',
    },

    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '55%',
    },
  },
  errorText: {
    top: '43%',
    position: 'absolute',

    '& > p': {
      fontFamily: 'Bud Light Easy Cond',
      fontSize: '1.125rem',
      lineHeight: '155%',
      fontStyle: 'normal',
      fontWeight: '500',
      textAlign: 'center',
      color: 'red',
    },

    // mobile portrait devices
    '@media (max-width: 500px)': {
      top: '44% !important',
    },

    // mobile portrait devices
    '@media (max-width: 400px)': {
      top: '42% !important',
    },

    // mobile landscape devices
    '@media (max-height: 450px)': {
      top: '31% !important',
    },

    // tab portrait devices
    '@media (max-width: 910px)': {
      top: '44%',
    },

    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '39%',
    },
  },
  formContainer: {
    top: '60%',
    // position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > form': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '@media (max-width: 900px)': {
      display: 'unset !important',
      top: '57% !important',
    },
    '@media (max-height: 450px)': {
      top: '53% !important',
    },
    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '62%',
    },
    '@media (max-height: 250px)': {
      top: '30% !important',
    },
  },
  description2: {
    top: '70%',
    position: 'absolute',
    '@media (max-width: 500px)': {
      top: '68% !important',
    },
    '@media (max-height: 450px)': {
      top: '68% !important',
    },
    // tab portrait devices
    '@media (max-width: 910px)': {
      top: '70%',
    },
    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '72%',
    },
  },
  description3: {
    top: '75%',
    width: '35%',
    position: 'absolute',
    '@media (max-width: 900px)': {
      top: '75% !important',
      width: '95%',
    },
    // tab portrait devices
    '@media (max-width: 910px)': {
      top: '75%',
    },
    // tab landscape devices
    '@media (max-height: 850px)': {
      top: '77%',
    },
  },
  ageInput: {
    margin: '5px',
    '& > input': {
      height: '48px',
      appearance: 'none',
      backgroundClip: 'padding-box',
      backgroundColor: '#fff',
      border: '1px solid #ced4da',
      borderRadius: '0.375rem',
      color: '#212529',
      textAlign: 'center',
      minWidth: { xs: '40px', sm: '60px' },
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '150%',
      padding: { xs: '0.375rem 0.5rem', sm: '0.375rem 0.75rem' },
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      width: 1,
      '&:focus': {
        backgroundColor: '#fff',
        borderColor: '#86b7fe',
        boxShadow: '0 0 0 0.25rem rgba(13,110,253,.25)',
        color: '#212529',
        outline: 0,
      },
    },
    '& > input::placeholder': {
      color: 'var(--new-light-blue-348-ccb, #348CCB)',
      textAlign: 'center',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Bud Light Easy Cond',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '800',
      lineHeight: '24px',
    },
    '&:last-of-type > input': {
      minWidth: '60px',
    },
  },
  submitButton: {
    width: { xs: '100px', md: '100px' },
    background: 'linear-gradient(248.6deg, #3DAAF2 0%, #0032C7 57.55%, #001C71 100%)',
    borderRadius: '48px',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#040825',
    },
    fontFamily: 'TitlingGothicFBNarrow-Standard',
    fontSize: '16px',
    marginLeft: '15px',
  },
  password: {
    display: 'flex',
    justifyContent: 'center',

    '& > input': {
      appearance: 'none',
      backgroundClip: 'padding-box',
      backgroundColor: '#fff',
      border: '1px solid #ced4da',
      borderRadius: '0.375rem',
      color: '#212529',
      marginRight: { xs: '0.5rem', sm: '1rem', md: '2rem' },
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '150%',
      padding: { xs: '0.375rem 0.5rem', sm: '0.375rem 0.75rem' },
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      width: 1,
      '&:focus': {
        backgroundColor: '#fff',
        borderColor: '#86b7fe',
        boxShadow: '0 0 0 0.25rem rgba(13,110,253,.25)',
        color: '#212529',
        outline: 0,
      },
    },
  },
}
