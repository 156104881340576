import { FC, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import InnerPage from '../InnerPage'
import { connect } from 'react-redux'
import { CombineInterface } from '../../store/reducers/interfaces'
import { rankGet } from '../../store/actions/LeaderboardActions'
import { LeaderBoardInterface } from './interfaces'
import { profile } from '../../store/actions/AuthActions'
import { Auth } from 'aws-amplify'
import Spinner from '../../core-ui/Spinner'

const LeaderBoard: FC<LeaderBoardInterface> = ({
  rankReducer: { getRankData, getRankStatus },
  rankGetAction,
  profileAction,
  authReducer: { profileStatus, profileData },
}) => {
  const [fullName, setFullName] = useState({
    firstName: '',
    lastName: '',
  })
  useEffect(() => {
    rankGetAction()
    profileAction()
  }, [])

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const userAttributes = await Auth.userAttributes(user)
        const attributesMap: Record<string, string> = {}
        if (attributesMap) {
          userAttributes.forEach((attribute) => {
            attributesMap[attribute.Name] = attribute.Value
          })
          setFullName({
            firstName: attributesMap['custom:firstName'] || attributesMap['name'],
            lastName: attributesMap['custom:lastName'],
          })
        }
      } catch (error) {
        console.error('Error fetching user attributes:', error)
      }
    }

    fetchUserAttributes()
  }, [])

  return (
    <>
      <InnerPage>
        <div className='container'>
          <div className={styles.leaderBoard_box}>
            <h1>Leaderboard</h1>
            <p>1 Correct Pick = 1 Entry</p>
          </div>
          <div className={styles.hr_box} />
          {getRankStatus === 'pending' || profileStatus === 'pending' ? (
            <div className='mb-5'>
              <Spinner />
            </div>
          ) : (
            <>
              <>
                {fullName.firstName ? (
                  <>
                    <div className={`${styles.table_box} ${styles.table_box_profile}`}>
                      <h6 className={styles.table_header_username}>My Rank</h6>
                      <div className={styles.table_header_rank}>
                        <h6>My Entries</h6>
                      </div>
                      <h6 className={styles.table_header_point}></h6>
                    </div>
                    <div className={`${styles.table_content} ${styles.table_content_profile}`}>
                      <div className={`${styles.table_item} ${styles.table_item_box_only}`}>
                        <div className={styles.table_username}>
                          <div className={styles.table_username_box}>
                            <p className={styles.table_username_text}>
                              {profileData?.data.rank}. {fullName.firstName} {fullName.lastName}
                            </p>
                          </div>
                        </div>
                        <div className={styles.table_rank}>
                          <p>{profileData?.data.entries || 0}</p>
                        </div>
                        <div className={styles.table_point}></div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div className={styles.table_box}>
                  <h6 className={styles.table_header_username}>Rank</h6>
                  <div className={styles.table_header_rank}>
                    <h6>Entries</h6>
                  </div>
                  <h6 className={styles.table_header_point}></h6>
                </div>
                <div className={`${styles.table_content} ${styles.table_content_users}`}>
                  {getRankData?.data.leaderboard.map(({ firstName, lastName, points, rank }) => (
                    <div key={rank} className={styles.table_item}>
                      <div className={styles.table_username}>
                        <div className={styles.table_username_box}>
                          <p className={styles.table_username_text}>
                            {rank}. {firstName} {lastName}
                          </p>
                        </div>
                      </div>
                      <div className={styles.table_rank}>
                        <p>{points}</p>
                      </div>
                      <div className={styles.table_point}></div>
                    </div>
                  ))}
                </div>
              </>
            </>
          )}
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ rankReducer, authReducer }: CombineInterface) => ({
  rankReducer,
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  rankGetAction: () => dispatch(rankGet()),
  profileAction: () => dispatch(profile()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(LeaderBoard)
