import React, { FC, useMemo } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { PasswordPopoverInterface } from '../interface'

const PasswordPopover: FC<PasswordPopoverInterface> = ({ password }) => {
  const containCharacterLength = () => {
    if (password.length >= 8) {
      return true
    }

    return false
  }

  const containNumber = () => {
    if (password && /\d/.test(password)) {
      return true
    }

    return false
  }

  const containUpper = () => {
    if (password && /[A-Z]/.test(password)) {
      return true
    }

    return false
  }

  const containLower = () => {
    if (password && /[a-z]/.test(password)) {
      return true
    }

    return false
  }

  const containCharacter = () => {
    if (password && /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
      return true
    }

    return false
  }

  const isPopupNeeded = useMemo(() => {
    if (
      containCharacterLength() &&
      containNumber() &&
      containUpper() &&
      containLower() &&
      containCharacter()
    ) {
      return false
    } else {
      return true
    }
  }, [password])

  return (
    <>
      {isPopupNeeded ? (
        <div className='password-popover-container'>
          <div className={`password-validation-item ${containLower() ? 'active' : ''}`}>
            <CheckOutlined />
            <p>Password must contain a lower case letter</p>
          </div>
          <div className={`password-validation-item ${containUpper() ? 'active' : ''}`}>
            <CheckOutlined />
            <p>Password must contain an upper case letter</p>
          </div>
          <div className={`password-validation-item ${containNumber() ? 'active' : ''}`}>
            <CheckOutlined />
            <p>Password must contain a number</p>
          </div>
          <div className={`password-validation-item ${containCharacterLength() ? 'active' : ''}`}>
            <CheckOutlined />
            <p>Password must contain at least 8 characters</p>
          </div>
          <div className={`password-validation-item ${containCharacter() ? 'active' : ''}`}>
            <CheckOutlined />
            <p>Password must contain at least one special character</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default PasswordPopover
