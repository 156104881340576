import React, { FC, useEffect, useState } from 'react'
import { DekstopGames } from '../interfaces'
import moment from 'moment-timezone'

moment.updateLocale('en', {
  // Specify the callback function for
  // customizing the values
  meridiem: function (hour, minute, isLowercase) {
    if (hour >= 12) return isLowercase ? 'p' : 'P'
    else return isLowercase ? 'a' : 'A'
  },
})

const DesktopGames: FC<DekstopGames> = ({ selectClick, weekGames }) => {
  const home = 'homeTeam'
  const away = 'awayTeam'
  const [sortedGames, setSortedGames] = useState<any>({})

  useEffect(() => {
    const sortedGames: any = {}

    weekGames.forEach((mat) => {
      // hiding games if match date is more than 24 hrs
      if (1 > moment().diff(mat.date || new Date(), 'days')) {
        const [key, day, date] = moment(mat.date)
          .tz('America/New_York')
          .format('ddddDD dddd M/D/yy')
          .split(' ')
        if (sortedGames[key]) {
          sortedGames[key].games.push(mat)
        } else {
          sortedGames[key] = { day, date, games: [mat] }
        }
      }
    })

    if (!weekGames.length) {
      const [key, day, date] = moment()
        .tz('America/New_York')
        .format('ddddDD dddd M/D/yy')
        .split(' ')
      sortedGames[key] = { day, date, games: [] }
    }

    setSortedGames(sortedGames)
  }, [weekGames])

  return (
    <>
      {Object.keys(sortedGames).map((dayId) => (
        <div className='pick-em-parent-container' key={dayId}>
          <div className='pick-em-child-container'>
            <div className='pick-em-card-header'>
              <div className='pick-em-card-logo'>
                <img src={require('../../../assets/images/em-games/logo-icon.svg').default} />
              </div>
              <div className='pick-em-card-details'>
                <p className='pick-em-card-details-day'>{sortedGames[dayId].day}</p>
                <p className='pick-em-card-details-date'>{sortedGames[dayId].date}</p>
              </div>
            </div>
            <div className='pick-em-card-text'>
              {!weekGames.length ? (
                <p className='no-games'>no games today</p>
              ) : (
                <p>PICK THE WINNER</p>
              )}
            </div>
            <div className='pick-em-card-list'>
              {sortedGames[dayId].games.map((game: any, index: number) => (
                <div className='pick-em-card' key={dayId + index}>
                  {/* <div className='pick-em-card-location'>
                    <p>{game[away].team.location}</p>
                    <p>VS</p>
                    <p>{game[home].team.location}</p>
                  </div> */}
                  <div className={'button_box'}>
                    <div
                      className={`${game[away].selected ? 'active' : ''} ${
                        game?.completed || game?.isGameLocked ? 'completed' : ''
                      } ${
                        game?.pickState?.winner?.winnerTeamId === game[away]?.id ? 'win-team' : ''
                      }`}
                    >
                      <img src={require('../../../assets/images/em-games/check.svg').default} />
                      <button
                        onClick={() => !game.isGameLocked && selectClick(game.eventId, away)}
                        disabled={game.isGameLocked}
                      >
                        {game[away].curatedRank.region}
                        {game[away].curatedRank.region ? <br /> : ''}
                        {game[away].curatedRank.region === 'TBD' ? (
                          ''
                        ) : (
                          <>Seed # {game[away].curatedRank.current}</>
                        )}
                        {/* {game[away].team.location.length < 11
                          ? game[away].team.location
                          : game[away].team.location.includes(' ')
                            ? game[away].team.location
                            : `${game[away].team.location.slice(0, 6)}-${game[away].team.location.slice(6)}`} */}
                      </button>
                    </div>
                    <span className='date-box'>
                      <p>VS</p>
                      <p>{moment(game.date).tz('America/New_York').format('LT')}M</p>
                    </span>
                    <div
                      className={`${game[home].selected ? 'active' : ''}  ${
                        game?.completed || game?.isGameLocked ? 'completed' : ''
                      } ${
                        game?.pickState?.winner?.winnerTeamId === game[home]?.id ? 'win-team' : ''
                      }`}
                    >
                      <img
                        className='right-img-box'
                        src={require('../../../assets/images/em-games/check.svg').default}
                      />

                      <button
                        onClick={() => !game.isGameLocked && selectClick(game.eventId, home)}
                        disabled={game.isGameLocked}
                      >
                        {game[home].curatedRank.region}
                        {game[home].curatedRank.region ? <br /> : ''}
                        {game[home].curatedRank.region === 'TBD' ? (
                          ''
                        ) : (
                          <>Seed # {game[home].curatedRank.current}</>
                        )}
                        {/* {game[home].team.location.length < 11
                          ? game[home].team.location
                          : game[home].team.location.includes(' ')
                            ? game[home].team.location
                            : `${game[home].team.location.slice(0, 6)}-${game[home].team.location.slice(6)}`} */}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default DesktopGames
